.image-component {
  margin: 0;
  width: 100%;
  position: relative;
}

.common-image-component {
  width: 100%;
  object-fit: cover;
  object-position: var(--focal-point-x) var(--focal-point-y);
}

.mainbanner-image {
  height: 43.75vw;
}
@media (max-width: 543.98px) {
  .mainbanner-image {
    height: 75vw;
  }
}

.common-image-filter {
  filter: brightness(40%);
}

.image-heading-container {
  position: absolute;
  top: 50%;
  width: 100%;
}
@media (min-width: 544px) {
  .image-heading-container {
    padding-left: 7%;
  }
}

.image-heading-text {
  font-family: "Helvetica Neue";
  color: #fff;
}

.ITC-image-heading-text {
  color: #fff;
}

.image-heading-container .mainbanner-sub-text p {
  font-size: 1.67rem;
  font-family: "Helvetica Neue";
  color: #fff;
  text-decoration: underline;
}

.ITC-text-underneath span {
  color: #000;
  padding-top: 0.75em;
  text-align: center;
}